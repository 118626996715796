<template>
  <div>
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="checkout page">
      <div class="">
        <div class="banner checkout-banner">
          <div class="container">
            <h4 class="title">Checkout</h4>
          </div>
        </div>
        <div class="content container">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(placeOrder)">
              <div class="row my-5 form_section">
                <div class="col-lg-8 col-md-7">
                  <div
                    v-if="$store.getters.isLoggedIn == false"
                    class="card card-1"
                  >
                    <div class="card-body">
                      <p>
                        Returning customer?
                        <a href="/login">Click here to login</a>
                      </p>
                    </div>
                  </div>
                  <!-- personal info -->
                  <div class="card card-2 mt-4">
                    <div class="card-body">
                      <div class="card-title d-flex">
                        <div class="num">1</div>
                        <h5 class="title">Personal Details</h5>
                      </div>
                      <div class="card-text mt-3 mx-md-5">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <validation-provider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                placeholder=" "
                                type="text"
                                class="form-control"
                                v-model="order.customer.firstname"
                              />
                              <label class="anim">First Name</label>
                              <span class="err_msg">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                          <div class="form-group col-md-6">
                            <validation-provider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                placeholder=" "
                                type="text"
                                class="form-control"
                                v-model="order.customer.lastname"
                              />
                              <label class="anim">Last Name</label>
                              <span class="err_msg">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="form-group">
                          <validation-provider
                            rules="required:email"
                            v-slot="{ errors }"
                          >
                            <input
                              placeholder=" "
                              type="email"
                              name="email"
                              class="form-control"
                              v-model="order.customer.email"
                            />
                            <label class="anim">Email Address</label>
                            <span class="err_msg">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                        <div class="form-group">
                          <validation-provider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <vue-tel-input
                              v-model="order.customer.phone"
                            ></vue-tel-input>
                            <span class="err_msg">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- delivery address -->
                  <div
                    v-if="order.delivery.method == 'delivery'"
                    class="card card-5 mt-4"
                  >
                    <div class="card-body">
                      <div class="card-title d-flex mb-0">
                        <div class="num">2</div>
                        <h5 class="title">Delivery Address</h5>
                      </div>
                      <h6 class="card-subtitle subtitle mb-2 ml-5">
                        Where should your order be delivered <br />
                        <span
                          class="delivery_address_err"
                          style="color: red; font-size: 10px; font-weight: 400"
                        ></span>
                      </h6>
                      <div class="card-text mt-3 mx-md-5">
                        <div
                          v-show="$store.getters.isLoggedIn"
                          v-if="$store.getters.isLoggedIn"
                          class=""
                        >
                          <div class="address row">
                            <div
                              v-if="Object.keys(default_address).length > 0"
                              class="col-sm-6"
                            >
                              <div class="address-box" style="cursor: pointer">
                                <div style="height: 23px">
                                  <span
                                    class="material-icons float-right dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    type="button"
                                    aria-expanded="false"
                                  >
                                    more_horiz
                                  </span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      @click.prevent="editAd(default_address)"
                                      class="dropdown-item"
                                      href=""
                                      data-toggle="modal"
                                      data-target="#addressform"
                                      >Edit Address</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      href=""
                                      @click.prevent="
                                        handleDelete(default_address.id)
                                      "
                                      >Remove Address</a
                                    >
                                  </div>
                                </div>
                                <div>
                                  <h5>{{ default_address.label }}</h5>
                                  <p>
                                    {{ default_address.address }},
                                    {{ default_address.area }},
                                    {{ default_address.city }},
                                    {{ default_address.state }}.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div
                                v-show="$store.getters.isLoggedIn"
                                id="addnew"
                                data-toggle="modal"
                                data-target="#addressform"
                              >
                                <a
                                  data-toggle="modal"
                                  @click.prevent="edit = false"
                                  data-target="#addressform"
                                >
                                  <img src="../assets/img/addnew.png" alt="" />
                                </a>
                                <p>Add new address</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="form-row">
                            <div class="form-group col-12">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  placeholder=" "
                                  type="text"
                                  class="form-control"
                                  v-model="order.delivery.label"
                                />
                                <label class="anim">Label( Office, Home)</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                            <div class="form-group col-12">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  placeholder=" "
                                  type="text"
                                  class="form-control"
                                  v-model="order.delivery.address"
                                />
                                <label class="anim">Street Address</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  placeholder=" "
                                  type="text"
                                  required
                                  class="form-control"
                                  v-model="order.delivery.landmark"
                                />
                                <label class="anim">Closest Landmark</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                            <div class="form-group col-md-6">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  placeholder=" "
                                  type="text"
                                  class="form-control"
                                  disabled
                                  v-model="order.delivery.state"
                                />
                                <label class="anim">State</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  placeholder=" "
                                  type="text"
                                  class="form-control"
                                  disabled
                                  v-model="order.delivery.city"
                                />
                                <label class="anim">City</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                            <div class="form-group col-md-6">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  placeholder=" "
                                  type="text"
                                  class="form-control"
                                  disabled
                                  v-model="order.delivery.area"
                                />
                                <label class="anim">Area</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- fulfillment info -->
                  <div class="card card-3 mt-4">
                    <div class="card-body">
                      <div class="card-title d-flex">
                        <div class="num">
                          <span v-if="order.delivery.method !== 'delivery'"
                            >2</span
                          >
                          <span v-else>3</span>
                        </div>
                        <h5 class="title">Delivery Information</h5>
                      </div>
                      <div class="card-text my-3 mx-md-5">
                        <p class="mode ml-5">
                          <span v-if="store.mode == 'Pickup'">Pickup At:</span>
                          <span v-else>We are picking your order from:</span>
                        </p>
                        <div class="d-flex fulfillment-address">
                          <img src="../assets/img/store-icon.png" alt="" />
                          <p>
                            {{ store.name }}, {{ store.address }},<br />
                            {{ store.state }}.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!---window -->
                  <div class="card card-4 mt-4">
                    <div class="card-body">
                      <div class="card-title d-flex mb-0">
                        <div class="num">
                          <span v-if="order.delivery.method !== 'delivery'"
                            >3</span
                          >
                          <span v-else>4</span>
                        </div>
                        <h5 class="title">Fulfillment Window</h5>
                      </div>
                      <h6 class="card-subtitle subtitle mb-2 ml-5">
                        Click on preferred day to view available windows
                        <br />
                        <span
                          class="fulfillment_err"
                          style="color: red; font-size: 10px; font-weight: 400"
                        ></span>
                      </h6>
                      <div class="card-text my-3 ml-5">
                        <div class="date-box">
                          <p
                            class="window-date wday"
                            v-bind:class="
                              row.window_day == 'Today' ? 'active' : ''
                            "
                            v-bind:style="
                              row.active == false ? 'color: lightgrey;' : ''
                            "
                            :id="'day' + index"
                            @click.prevent="listWindows(row, 'day' + index)"
                            v-for="(row, index) in windows"
                            v-bind:key="index"
                          >
                            {{ row.window_day }}
                          </p>
                        </div>
                        <div class="row mr-5 mt-3" v-if="!window_set">
                          <div class="text-center col-md-12 mt-2" qaz>
                            <div
                              style="
                                height: 150px;
                                width: 150px;
                                margin: 0 auto;
                              "
                            >
                              <img
                                style="width: 100%"
                                src="https://www.c-sgroup.com/images/loading-icon-red.gif"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else class="row mr-5 mt-3">
                          <div
                            v-if="open_windows.length == 0"
                            class="text-center col-md-12 mt-2"
                            qaz
                          >
                            <p>There is no availability for this date.</p>
                          </div>
                          <div
                            v-else
                            v-for="(row, index) in open_windows"
                            v-bind:key="index"
                            class="col-lg-6 col-md-12 mt-2"
                          >
                            <div
                              @click="setWindow(row, index)"
                              v-bind:class="
                                selected_window == row.id + '' + index
                                  ? 'active'
                                  : ''
                              "
                              class="window"
                            >
                              <div style="height: 25px">
                                <img
                                  src="../assets/img/checked.png"
                                  alt=""
                                  class="check float-right"
                                />
                              </div>
                              <div
                                class="d-flex justify-content-between"
                                style="flex-flow: wrap"
                              >
                                <p class="hour">
                                  {{ row.starttime + " - " + row.endtime }}
                                </p>
                                <div class="fee">
                                  <h5
                                    v-if="order.delivery.method == 'delivery'"
                                  >
                                    Delivery Fee
                                  </h5>
                                  <h5 v-else>Pickup Fee</h5>

                                  <p v-if="order.delivery.method == 'pickup'">
                                    FREE
                                  </p>
                                  <p
                                    v-else-if="
                                      isPromo && order.cart_subtotal >= 20000
                                    "
                                  >
                                    FREE
                                  </p>
                                  <p v-else>
                                    ₦{{ Math.round(row.deliveryfee) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="text-center mt-2 col-md-12"
                            style="
                              font-size: 12px;
                              font-style: italic;
                              font-weight: bold;
                            "
                          >
                            <span style="color: black">Disclaimer:</span>
                            <span style="color: red"
                              >We typically deliver most of our orders with the
                              delivery fee paid online. However, on rare
                              occasions, we might contact you to give you an
                              update on the delivery fee depending on the weight
                              of your items or the delivery distance.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 3rd party-->
                  <div class="card card-5 mt-4">
                    <div class="card-body">
                      <div class="card-title d-flex mb-0">
                        <div class="num">
                          <span v-if="order.delivery.method !== 'delivery'"
                            >4</span
                          >
                          <span v-else>5</span>
                        </div>
                        <h5 class="title">3rd Party Contacts</h5>
                      </div>
                      <h6 class="card-subtitle subtitle mb-2 ml-5">
                        Who should we contact to follow up on this order?
                      </h6>
                      <div class="card-text mt-3 mx-md-5">
                        <fieldset>
                          <legend>Order Enquiry Contact*</legend>

                          <div class="form-row">
                            <div class="form-group col-12">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  placeholder=" "
                                  type="text"
                                  class="form-control"
                                  v-model="order.order_enquiry_contactname"
                                />
                                <label class="anim">Name</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <vue-tel-input
                                  v-model="order.order_enquiry_contactnumber"
                                  class="contact_method_err"
                                ></vue-tel-input>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group form-check">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="exampleCheck1"
                              @change="check($event, 'default')"
                            />
                            <label class="form-check-label" for="exampleCheck1"
                              >Same as personal details</label
                            >
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend>Contact Upon Pickup*</legend>

                          <div class="form-row">
                            <div class="form-group col-12">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=" "
                                  v-model="order.contact_upon_delivery_name"
                                />
                                <label class="anim">Name</label>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <vue-tel-input
                                  v-model="order.contact_upon_delivery_number"
                                ></vue-tel-input>
                                <span class="err_msg">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group form-check">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              @change="check($event, 'enquiry')"
                            />
                            <label class="form-check-label" for="exampleCheck1"
                              >Same as order enquiry</label
                            >
                          </div>
                        </fieldset>

                        <div class="contact mt-5">
                          <h5 class="title m-0">
                            How should your contacts be reached
                            <br />
                            <span
                              class="contact_err"
                              style="
                                color: red;
                                font-size: 10px;
                                font-weight: 400;
                              "
                            ></span>
                          </h5>
                          <div class="row mt-3">
                            <div
                              @click="order.delivery.contact_method = 'call'"
                              v-bind:class="
                                order.delivery.contact_method == 'call'
                                  ? 'active'
                                  : ''
                              "
                              class="contact-type col-3"
                            >
                              <div class="icon">
                                <img src="../assets/img/phone.png" alt="" />
                              </div>
                              <p>Phone Call</p>
                            </div>
                            <div
                              @click="
                                order.delivery.contact_method = 'whatsapp'
                              "
                              v-bind:class="
                                order.delivery.contact_method == 'whatsapp'
                                  ? 'active'
                                  : ''
                              "
                              class="contact-type col-3"
                            >
                              <div class="icon">
                                <img src="../assets/img/whatsapp.png" alt="" />
                              </div>
                              <p>Whatsapp</p>
                            </div>
                            <div
                              @click="order.delivery.contact_method = 'email'"
                              v-bind:class="
                                order.delivery.contact_method == 'email'
                                  ? 'active'
                                  : ''
                              "
                              class="contact-type col-3"
                            >
                              <div class="icon">
                                <img src="../assets/img/email.png" alt="" />
                              </div>
                              <p>Email</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- comment -->
                  <div class="card card-6 mt-4">
                    <div class="card-body">
                      <div class="card-title d-flex">
                        <div class="num">
                          <span v-if="order.delivery.method !== 'delivery'"
                            >5</span
                          >
                          <span v-else>6</span>
                        </div>
                        <h5 class="title">Add note/instruction</h5>
                      </div>
                      <div class="card-text mt-3 mx-md-5">
                        <div class="form-group">
                          <textarea
                            v-model="order.comment"
                            name=""
                            class="tph"
                            placeholder="e.g. an item you want but didn't find OR special instructions about your order."
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-5">
                  <div id="summary">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title title">Your Order</h5>
                        <table class="table table-responsive">
                          <tbody class="body1">
                            <tr v-for="(row, i) in cart" :key="i">
                              <td>
                                {{ row.quantity }}x {{ row.product.name }}
                              </td>
                              <td class="float-right">
                                ₦{{ formatPrice(row.price) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table class="table">
                          <tbody class="body2">
                            <tr>
                              <td>Subtotal :</td>
                              <td class="float-right">
                                ₦{{ formatPrice(order.cart_subtotal) }}
                              </td>
                            </tr>

                            <tr v-if="order.delivery.charge > 0">
                              <td>+ Delivery Fee :</td>
                              <td class="float-right">
                                <span
                                  v-if="isPromo && order.cart_subtotal >= 20000"
                                  >Free</span
                                >
                                <span v-else>
                                  <span
                                    v-if="
                                      order.delivery.method == 'delivery' &&
                                      order.delivery.charge !== null
                                    "
                                    >₦{{ Math.round(deliveryFee) }}</span
                                  >
                                  <span
                                    v-else-if="
                                      order.delivery.method == 'delivery' &&
                                      order.delivery.charge == null
                                    "
                                    >₦0.00</span
                                  >
                                  <span v-else>Pickup(Free)</span>
                                </span>
                              </td>
                            </tr>

                            <!-- <tr v-if="isLoggedIn">
                              <td v-if="walletVisible"> - Wallet Deduction :</td>
                              
                              <td v-if="walletVisible" class="float-right ">₦{{formatPrice(order.cart_subtotal + deliveryFee)}} </td>
                            </tr>-->
                            <!-- <tr v-if="isLoggedIn">
                              <td  v-if="giftVisible"> - Giftcard Deduction : </td>
                              <td v-if="giftVisible" class="float-right ">₦{{formatPrice(giftcard_amount)}}</td>
                            </tr> -->
                            <!-- <tr v-if="isLoggedIn && topup_amount > 0">
                              <td > + Topup Wallet : </td>
                              <td  class="float-right ">₦{{formatPrice(top_up_transaction.amount == "" ? top_up_transaction.amount_checked : top_up_transaction.amount)}}</td>
                            </tr>-->
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Total Due :</th>
                              <th class="float-right total">
                                ₦{{ formatPrice(ordertotal) }}
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                        <!-- <b> <p style="margin-top:-18px">Top Up your Wallet</p></b>
                        <div class="row ">
                         
                          <small style="color:black ;  margin-top: -17px; font-size: 8px;  margin-left: 17px;"><i>
                            Add funds to your Market Square e-wallet for a speedy checkout next time
                            </i></small>
                         <div class="container">
                           
                           <label class="famount" >
                             <input type="radio" name="amount"  @click="toggleamount(20000)">
                             
                             <span style="top: -3px;position: relative;"
                             >₦10,000</span>
                             
                             </label>
                           
                           <label class="famount"  >
                             <input type="radio" name="amount" @click="toggleamount(20000)">
                              <span style="top: -3px;position: relative;"
                             >₦20,000</span>
                             </label>
                             
                              <label class="famount" >
                             <input type="radio" name="amount" @click="toggleamount(40000)">

                              <span style="top: -3px;position: relative;"
                             >₦40,000</span>
                             </label>

                             <input type="text" class="paywalletinput" 
                             onkeypress="return event.charCode >= 48"
                             @keydown="toggleamount"
                              placeholder=" Other"  v-model="top_up_transaction.amount" > 
                     
                     
                                           
                                   <label class="famount" @click="clickNo">
                             <input type="radio" name="amount" @click=" top_up_transaction.amount_checked = 0" >
                             
                                  <span style="top: -3px;position: relative;"
                             >None</span>
                             </label>
                         </div>
                          
                          
                          
                        </div>-->
                      </div>
                    </div>
                    <div>
                      <!-- <div class="form-group form-check pay mt-3" v-show="$store.getters.isLoggedIn"> -->
                      <!-- <input
                          id='voucherCheck'
                          type="checkbox"
                          class="form-check-input"
                          @click="togglegiftcard"
                          v-model="payment.voucher"
                          @change="paymethod($event, 'voucher')"
                        /> -->
                      <!-- :disabled="isLoggedIn && Number(balance) == 0" -->
                      <!-- <label class="form-check-label">Pay with Giftcard
                          <br>
                          <span>Got a voucher or Gift card?</span>
                        </label>
                        <small
                          class="ml-2"
                          id="statusvoucher"
                          style="color:red;font-size:11px"
                        ></small> -->
                      <!-- </div> -->
                      <!-- <div
                        v-if="payment.voucher"
                        class="form-group"
                      >
                        <div class="form-row px-2">
                          <input
                            type="text"
                            class="form-control col-7"
                            placeholder="Enter serial no."
                            v-model="serialnumber"
                          >
                          <button
                            @click.prevent="verifyMethod('voucher')"
                            class="btn mt-1 ml-1 col-4"
                          >Verify</button>
                        </div>

                      </div> -->

                      <div class="form-group form-check pay mt-3">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          :checked="selectedPaymentMethod === 'paystack'"
                          @change="paymethod($event, 'paystack', 'card')"
                        />
                        <!-- :disabled="isLoggedIn && Number(balance) == 0" -->
                        <label class="form-check-label">
                          <b>Pay with Paystack - USSD, Bank Transfer or Card</b>
                          <small
                            class="ml-2"
                            id="balance"
                            style="color: red; font-size: 11px"
                          ></small>
                          <br />
                          <span
                            >Pay with Paystack (Card, Bank Transfer or
                            USSD)</span
                          >
                        </label>
                      </div>

                      <div class="form-group form-check pay mt-3">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          :checked="selectedPaymentMethod === 'flutterwave'"
                          @change="paymethod($event, 'flutterwave', 'card')"
                        />
                        <!-- :disabled="isLoggedIn && Number(balance) == 0" -->
                        <label class="form-check-label">
                          <b>Pay with Flutterwave - PayPal, USSD, Bank Transfer or Card</b>
                          <small
                            class="ml-2"
                            id="balance"
                            style="color: red; font-size: 11px"
                          ></small>
                          <br />
                          <span
                            >Pay with Flutterwave (Card, Bank Transfer or
                            USSD)</span
                          >
                        </label>
                      </div>

                      <div class="form-group form-check agree">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="clearance"
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >I accept the
                            <a
                              href="/terms"
                              style="color: #000066; font-weight: bold"
                              >terms and conditions</a
                            >
                            of marketsquare</label
                          >
                          <span class="err_msg">{{ errors[0] }}</span>
                        </validation-provider>
                      </div>

                      <!-- <div class="" v-if="user.available_balance > 0">
                        <input v-model="wallet_pin_verify" autocomplete="off" type="password" class="form-control" placeholder="Enter your wallet pin" style="width: 90%; margin: 0 auto;">
                      </div> -->

                      <!-- <button  v-bind:disabled="!canPay" v-bind:class="!canPay ? 'disabled': ''">Complete Payment</button> -->

                      <button
                        v-bind:disabled="!canPay"
                        v-bind:class="!canPay ? 'disabled' : ''"
                        type="submit"
                      >
                        Proceed to Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!-- walletPin Modal -->
    <div class="form-group form-check pay mt-3">
      <div
        class="modal fade"
        id="walletpinmodal"
        tabindex="-2"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="card-title title">Enter Your Wallet Pin</h5>
              <br />

              <div class="" v-if="user.available_balance > 0">
                <input
                  v-model="wallet_pin_verify"
                  autocomplete="off"
                  type="password"
                  class="form-control"
                  placeholder="Enter your wallet pin"
                  style="width: 90%; margin: 0 auto; margin-left: -28px"
                />
              </div>
              <button
                class="msq-button mt-3"
                v-bind:class="!canPay ? 'disabled' : ''"
                @click="walletPin"
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Address Modal -->
    <div
      class="modal fade"
      id="addressform"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              style="font-size: 15px; font-weignt: 600"
              class="modal-title text-left"
            >
              {{ edit ? "Edit" : "Add" }} Delivery address <br />
              <small v-if="edit" style="font-size: 11px; color: #859bbcf5"
                >Edit address.</small
              >
              <small v-else style="font-size: 11px; color: #859bbcf5"
                >Add where you will like to make your deliveries.</small
              >
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(createAddress)">
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        required
                        placeholder=" "
                        v-model="address.label"
                      />
                      <label for="" class="anim">Label e.g Home, Office</label>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group col-sm-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        required
                        class="form-control"
                        placeholder=" "
                        v-model="address.address"
                      />
                      <label class="anim">Street Address</label>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        required
                        class="form-control"
                        placeholder=" "
                        disabled
                        v-model="address.area"
                      />
                      <label class="anim">Area</label>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group col-sm-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        required
                        class="form-control"
                        placeholder=" "
                        v-model="address.landmark"
                      />
                      <label class="anim">Closest Landmark</label>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        required
                        disabled
                        class="form-control"
                        placeholder=" "
                        v-model="address.state"
                      />
                      <label class="anim">State</label>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group col-sm-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        required
                        class="form-control"
                        disabled
                        placeholder=" "
                        v-model="address.city"
                      />
                      <label class="anim">City</label>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="address.address_default"
                    />

                    <label class="form-check-label" for="exampleCheck1"
                      >Set as default</label
                    >
                  </div>
                </div>
                <button type="submit" class="msq-button mt-4">
                  {{ edit ? "Update" : "Add" }} Address
                </button>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import * as $ from "jquery";
import Tops from "@/components/Tops.vue";
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
import PaystackPop from "@paystack/inline-js";
import axios from "axios";

export default {
  name: "Checkout",
  components: {
    TopNav,
    Footer,
    Tops,
  },
  data() {
    return {
      walletVisible: false,
      amountVisible: false,
      deliveryVisible: false,
      giftVisible: false,
      selectedPaymentMethod: "", // Track selected payment method
      wallet_pin_verify: "",
      delivery_fee_variation: {
        basket_size: 1,
        delivery_area: 1,
      },
      isPromo: false,
      selected_date: "",
      window_end_promo: "",

      transaction: {
        balance: 0,
        user_id: this.$store.getters.user.id,
        type: "DEBIT",
        amount: 0,
      },
      top_up_transaction: {
        balance: 0,
        user_id: this.$store.getters.user.id,
        type: "CREDIT",
        amount: "",
        amount_checked: 0,
      },
      isLoggedIn: this.$store.getters.isLoggedIn,
      edit: false,
      clearance: "",
      selected_window: "",
      window_set: false,
      user: {},
      available_balance: 0,
      balance: 0,
      giftcard_amount: "",
      store: {},
      cart: [],
      serialnumber: "",
      voucher: 0,
      addresslist: [],
      default_address: {},
      address: {
        user_id: this.$store.getters.user.id,
        label: "",
        area: "",
        state: "",
        address: "",
        city: "",
        landmark: "",
        address_default: 1,
      },
      windows: [],
      open_windows: [],
      payment: {
        voucher: false,
        loyalty: false,
        card: false,
      },
      order: {
        cart_id: "",
        user_id: "",
        order_type: "",
        unique_code: "",
        comment: "",
        amount_paid: 0,
        customer: {
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
        },
        delivery: {
          id: "",
          method: "",
          charge: "",
          hour: "",
          label: "",
          address: "",
          city: "",
          state: "",
          area: "",
          landmark: "",
          contact_method: "",
        },

        order_enquiry_contactname: "",
        order_enquiry_contactnumber: "",
        contact_upon_delivery_name: "",
        contact_upon_delivery_number: "",
        payment: {
          method: "",
          type: "",
          id:""
        },
        store: "",
        cart_subtotal: 0,
        order_total: 0,
        order_items: "",
      },
    };
  },
  created() {
    this.store = this.$store.getters.store;

    let rave = document.createElement("script");

    rave.setAttribute(
      "src",
      this.$request.ravePaystackAPI,
      // this.$request.raveFlutterwaveAPI
      // "https://ravesandboxapi.flutterwave.com/flwv3-pug/getpaidx/api/flwpbf-inline.js"
      // "https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js"
    );
    document.head.appendChild(rave);

    /* for free delivery promotion */
    let startstring = "Nov 22, 2023 00:00:59"; //this.$store.getters.startstring;
    let futurestring = "Nov 29, 2023 23:59:59"; // this.$store.getters.futurestring;

    let today = new Date().getTime();
    let start_promo = new Date(startstring).getTime();
    let end_promo = new Date(futurestring).getTime();

    this.window_end_promo = end_promo;

    if (today <= end_promo && today > start_promo) {
      this.isPromo = true;
    } else {
      this.isPromo = false;
    }
  },
  async mounted() {
    await this.getProductInCart();

    this.user = this.$store.getters.user;
    this.cart = this.$store.getters.cart;
    this.order.order_items = this.cart;
    this.order.user_id = this.user.id;
    this.order.order_type = "Groceries";
    this.order.customer.firstname = this.user.firstname;
    this.order.customer.lastname = this.user.lastname;
    this.order.customer.email = this.user.email;
    this.order.customer.phone = this.user.phone;
    this.order.store = this.store.id.toString();
    if (this.store.mode == "Delivery") {
      this.order.delivery.method = "delivery";
      this.order.delivery.city = this.store.city;
      this.order.delivery.state = this.store.state;
      this.order.delivery.area = this.$store.getters.area;
      setTimeout(() => {
        this.fetchAddress();
      }, 2000);
      this.address.state = this.store.state;
      this.address.city = this.store.city;
      this.address.area = this.$store.getters.area;
    } else {
      this.order.delivery.method = "pickup";
    }
    this.cart.forEach((i) => {
      this.order.cart_subtotal += Number(i.price);
    });

    if (this.isPromo && this.order.order_total >= 20000) {
      console.log("is promo window");
    } else await this.fetchDeliveryFeeVariation();
    this.showWallet();
    this.fetchWindow();
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = "Check Out Page";
      },
    },

    edit(val) {
      if (val == false) {
        this.address.state = this.store.state;
        this.address.city = this.store.city;
        this.address.area = this.$store.getters.area;
        this.address.address_default = 1;
      }
    },
  },
  computed: {
    topup_amount() {
      return Number(
        this.top_up_transaction.amount == ""
          ? this.top_up_transaction.amount_checked
          : this.top_up_transaction.amount
      );
    },

    canPay() {
      // if(this.isLoggedIn) {
      //   return (Number(this.user.available_balance) == 0 ||
      //    Number(this.user.available_balance) > 0) && this.balance  > 0 && this.payment.card ||
      //     (Number(this.user.available_balance) ||
      //     this.payment.voucher) > 0 && this.balance == 0 && this.clearance;
      // }
      // else {
      return !(
        (this.balance == this.order.order_total || this.balance > 0) &&
        this.payment.card == false
      );
      // }
    },

    deliverwindowfee() {
      let newresult =
        Number(this.order.delivery.charge) *
        (1 + Number(this.delivery_fee_variation.delivery_area) / 100) *
        (1 + Number(this.delivery_fee_variation.basket_size) / 100);
      return newresult;
    },

    deliveryFee() {
      // console.log(deliveryFee);

      // return result;

      // return isNaN(result) ||result == undefined ? 0 : result;

      return this.order.delivery.charge;
    },

    // deliveryFee () {
    // let result = Number(this.order.delivery.charge) + (Number(this.order.delivery.charge) * (Number(this.delivery_fee_variation.delivery_area)/100)) + (Number(this.order.delivery.charge) * (Number(this.delivery_fee_variation.basket_size)/100));
    //return isNaN(result) || result == undefined ? 0 : result;
    //return this.order.delivery.charge;
    // },

    // ordertotal () {
    //   let total = (Number(this.order.cart_subtotal) + Number(this.deliveryFee));
    //   this.order.order_total = total;

    //   if(this.isLoggedIn) {
    //     let available_balance =  Number(isNaN(this.user.available_balance) ? 0 : this.user.available_balance);
    //     let top_up = this.topup_amount
    //     let balance = available_balance - total;

    //     console.log(available_balance);

    //     if(balance < 0) {
    //       balance = balance * -1;
    //       this.available_balance = 0;
    //       this.transaction.amount =  -1 * available_balance;
    //       this.order.amount_paid = (total - balance);
    //     }
    //     else {
    //       this.order.amount_paid = (available_balance - balance);
    //       this.transaction.amount = -1 * this.order.amount_paid;
    //       this.available_balance = balance;
    //       balance = 0;
    //     }
    //     this.balance = balance;
    //     if(top_up > 0){
    //        this.balance = top_up + this.balance;
    //     }
    //     return this.balance;
    //   }
    //   else {
    //     this.balance = total;
    //     return total;

    //   }
    // },

    ordertotal() {
      let total =
        Number(this.order.cart_subtotal) + Math.round(this.deliveryFee);
      this.order.order_total = total;
      this.balance = total;
      return total;
      console.log();
    },
  },
  methods: {
    async getProductInCart() {
      let cart = this.$store.getters.cart;

      let productsInCart = cart.reduce((acc, prev) => {
        acc.push(prev.product.id);
        return acc;
      }, []);

      let req = {
        what: "getProductInCart",
        showLoader: false,
        params: {
          cart: JSON.stringify(productsInCart),
        },
      };

      await this.$request
        .makeGetRequest(req)
        .then((res) => {
          // if (res.type == 'getproduct') {
          let pro = res.data.data;
          if (pro.length > 0) {
            cart = cart.map((item) => {
              item.product.price = pro.find(
                (product) => item.product.id == product.id
              ).sellingprice;
              item.unit_price = item.product.price;

              item.price = item.quantity * item.unit_price;
              return item;
            });
          }
          this.$store.dispatch("addToCart", cart);
          this.loader.hide();
          // }
        })
        .catch((error) => {});
    },
    showWallet() {
      if (this.user.available_balance == 0) {
        this.walletVisible = false;
      } else {
        this.walletVisible = true;
      }
    },
    DeliveryNo() {
      this.deliveryVisible = false;
    },
    toggledelivery() {
      this.deliveryVisible = !this.giftVisible;
    },

    clickNo() {
      this.amountVisible = false;
    },
    toggleamount(amount) {
      this.top_up_transaction.amount_checked = amount;
      if (this.top_up_transaction.amount_checked > 0) {
        this.amountVisible = true;
      } else {
        this.amountVisible = false;
      }
    },

    giftcardNo() {
      this.amountVisible = false;
    },
    togglegiftcard() {
      this.giftVisible = !this.giftVisible;
    },

    async fetchDeliveryFeeVariation() {
      let req = {
        what: "deliveryFeeVariation",
        showLoader: false,
        params: {
          subtotal: Number(this.order.cart_subtotal),
          store_id: this.store.id,
          area: this.order.delivery.area,
        },
      };
      await this.$request
        .makeGetRequest(req)
        .then((response) => {
          this.delivery_fee_variation = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchWindow() {
      let req = {
        what: "windows",
        showLoader: false,
        params: {
          deliverywindow: this.store.mode,
          storeid: this.store.id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          if (response.type == "windows") {
            let vm = this;
            let today = new Date();
            const d = new Date(today);
            d.setDate(d.getDate() + 1);
            var dateString = new Date(
              today.getTime() - today.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0];
            var nextdateString = new Date(
              d.getTime() - d.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0];
            response.data.data.forEach((i) => {
              if (i.open_window.length == 0) {
                i.active = false;
              } else {
                i.active = true;
              }
              if (dateString == i.window_date) {
                i.window_day = "Today";
              } else if (nextdateString == i.window_date) {
                i.window_day = "Tomorrow";
              } else {
                i.window_day =
                  i.window_day.substring(0, 3) +
                  ", " +
                  vm.formatDate(i.window_date);
              }

              i.open_window = i.open_window.map((i) => {
                let result =
                  Number(i.deliveryfee) *
                  (1 +
                    Number(this.delivery_fee_variation.delivery_area) / 100) *
                  (1 + Number(this.delivery_fee_variation.basket_size) / 100);
                i.deliveryfee = result;
                return i;
              });
            });
            let sortedActivities = response.data.data
              .slice()
              .sort(
                (a, b) => new Date(b.window_date) - new Date(a.window_date)
              );
            this.windows =
              sortedActivities.length > 0 ? sortedActivities.reverse() : [];

            this.window_set = true;
            if (this.windows != []) {
              this.listWindows(this.windows[0], "day0");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formatPrice(price) {
      if (price == undefined || price == null) price = 0;

      // price = Math.ceil(price);
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },

    formatDate(date) {
      // let d = new Date(date)
      // return d.toDateString();

      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      var t = new Date(date);
      return monthShortNames[t.getMonth()] + " " + t.getDate();
    },

    verifyMethod(method) {
      let req = {
        what: "verifycard",
        showLoader: false,
        data: {
          serviceid: "351817683",
          serialnumber: this.serialnumber,
        },
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          console.log(res);
          if (method == "voucher") {
            // this.payment.voucher = true;
            this.giftcard_amount = res.data.data;

            if (this.giftcard_amount == 0) {
              document.getElementById("statusvoucher").textContent =
                "Voucher balance is 0. Cannot use this voucher.";
              this.payment.voucher = false;

              // Automatically select other payment method
              this.payment.card = true;
              // document.querySelector('input[type="checkbox"][value="card"]').checked = true;
            } else {
              this.payment.voucher = true;
              document.getElementById("statusvoucher").textContent =
                "₦" + this.formatPrice(this.giftcard_amount);

              // Deduct gift card amount from total cart price
              this.balance =
                Number(this.order.order_total) - Number(this.giftcard_amount);
              document.getElementById("balance").textContent =
                "Balance= ₦" + this.formatPrice(this.balance);
            }
          }
          console.log("order_total:" + this.order.order_total);
          console.log("giftcard_amount:" + this.giftcard_amount);
          console.log("balance:" + this.balance);
          // document.getElementById('statusvoucher').textContent = '₦' + this.formatPrice( this.giftcard_amount);
          // console.log(this.balance);
          // this.balance = Number(this.balance) - Number(res.data.data)
          // console.log(this.balance);
          // document.getElementById('balance').textContent = 'Balance= ₦' + this.formatPrice(this.balance);
        })
        .catch((error) => {
          if (method == "voucher") {
            document.getElementById("statusvoucher").textContent = error;
            this.payment.voucher = false;
          }
          // else {
          //   document.getElementById('statusloyalty').textContent = res;
          //   this.payment.loyalty = false;
          // }
        });
    },

    formatUnique(n) {
      return Number(n) > 9 ? "" + n : "0" + n;
    },

    setWindow(row, index) {
      this.order.delivery.hour = row.starttime + " - " + row.endtime;
      this.selected_window = row.id + "" + index;

      row.deliveryfee = Math.round(row.deliveryfee); // rounding up the delivery fee

      if (row.deliveryfee !== null) {
        if (this.isPromo && this.order.cart_subtotal >= 20000) {
          this.order.delivery.charge = 0;
        } else {
          this.order.delivery.charge = Math.round(row.deliveryfee);
        }
      }
    },

    paymethod($event, method, type) {
      if ($event.target.checked) {
        // Ensure only one payment method is selected at a time
        this.selectedPaymentMethod = method;
        // Reset all payment types before setting the selected one
        this.payment = { voucher: false, loyalty: false, card: false };
        if (type === "voucher") {
          this.payment.voucher = true;
        } else if (type === "loyalty") {
          this.payment.loyalty = true;
        } else if (type === "card") {
          this.payment.card = true;
        }
      } else {
        // If unchecked, reset the selected payment method
        this.selectedPaymentMethod = "";

        if (type === "voucher") {
          this.payment.voucher = false;
          this.balance = this.order.order_total;
          document.getElementById("statusvoucher").textContent = "";
          document.getElementById("balance").textContent = "";
          this.serialnumber = "";
          console.log(this.balance);
        } else if (type === "loyalty") {
          this.payment.loyalty = false;
          this.balance = ""; // Reset balance when loyalty is unchecked
        } else if (type === "card") {
          this.payment.card = false;
        }
      }

      console.log("Selected Payment Method:", this.selectedPaymentMethod);
      console.log("Payment Details:", this.payment);
    },

    check($event, action) {
      if ($event.target.checked) {
        if (action == "default") {
          this.order.order_enquiry_contactname =
            this.order.customer.firstname + " " + this.order.customer.lastname;
          this.order.order_enquiry_contactnumber = this.order.customer.phone;
        } else {
          this.order.contact_upon_delivery_name =
            this.order.order_enquiry_contactname;
          this.order.contact_upon_delivery_number =
            this.order.order_enquiry_contactnumber;
        }
      } else {
        if (action == "default") {
          this.order.order_enquiry_contactname = "";
          this.order.order_enquiry_contactnumber = "";
        } else {
          this.order.contact_upon_delivery_name = "";
          this.order.contact_upon_delivery_number = "";
        }
      }
    },

    listWindows(row, index) {
      this.order.delivery.deliverydate = row.window_date;
      this.selected_date = new Date(row.window_date).getTime();

      //isPromo
      if (this.selected_date > this.window_end_promo) {
        this.isPromo = false;
      } else {
        this.isPromo = true;
      }

      row.open_window.forEach((i) => {
        i.id = index;
      });
      this.open_windows = row.open_window;
      console.log(this.open_windows);

      this.fee = Math.round(row.deliveryfee);

      var add = document.querySelectorAll(".wday");
      [].forEach.call(add, function (el) {
        el.classList.remove("active");
      });
      if (index) {
        document.getElementById(index).classList.add("active");
      }
      console.log(this.deliveryfee);
    },

    makeTransaction(type, data) {
      data.unique_code = this.order.unique_code;
      let req = {
        what: type,
        showLoader: true,
        data: data,
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {})
        .catch((error) => {
          console.log(error);
          this.$swal.fire("Error", error.message, "error");
        });
    },

    walletPin() {
      if (this.wallet_pin_verify != "") {
        var w_req = {
          what: "verify_walletpin",
          showLoader: false,
          data: {
            verify_pin: this.wallet_pin_verify,
            phone: this.$store.getters.user.phone,
          },
        };
        this.$request
          .makePostRequest(w_req)
          .then((response) => {
            console.log(response.data.data);
            if (response.data.data == "ok") {
              this.finaliseOrder();
            } else {
              this.$swal.fire("Error", "wallet pin is incorrect!", "error");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire("Error", error, "error");
          });
      } else {
        console.log("is else");
        this.$swal.fire(
          "Error",
          "Kindly enter your wallet pin to continue",
          "error"
        );
      }
    },

    // Recent modification
    // placeOrder () {
    //   console.log("default_address:",this.default_address.address);
    //   console.log("delivery_address:",this.order.delivery.address);
    //   console.log("delivery_method:",this.order.delivery.method);

    //   if (this.isLoggedIn && this.default_address.address == undefined && this.order.delivery.method == 'delivery'){
    //       $('html, body').animate({ scrollTop: $(".form_section").offset().top }, 1200);
    //       $(".delivery_address_err").html("Kindly enter your delivery address.");
    //   } else if((this.order.delivery.address == '' || this.order.delivery.city == '' || this.order.delivery.state == '' || this.order.delivery.landmark == '') && this.order.delivery.method == 'delivery'){
    //       $('html, body').animate({ scrollTop: $(".form_section").offset().top }, 1200);
    //       $(".delivery_address_err").html("Kindly enter your delivery address.");
    //       return;
    //   }

    //   this.order.order_type= "Groceries";
    //   this.order.unique_code = "Gr" + this.formatUnique(this.order.store) + this.formatUnique(this.store.branch_code) + Math.floor(20000 + Math.random() * 90000);
    //   this.order.contact_upon_delivery_number = this.order.contact_upon_delivery_number.replace(/\s/g, '');
    //   this.order.order_enquiry_contactnumber = this.order.order_enquiry_contactnumber.replace(/\s/g, '');
    //   this.order.customer.phone = this.order.customer.phone.replace(/\s/g, '');
    //   let isValidate = [];
    //   let field = [];

    //   if (Number(this.user.available_balance) >  0) {
    //     if (this.order.payment.method.toLowerCase().includes("wallet") == false) {
    //       this.order.payment.method += " wallet"
    //     }
    //   }
    //   else {
    //     if (this.order.payment.method.toLowerCase().includes("wallet")) {
    //       this.order.payment.method = this.order.payment.method.replace(' wallet', '')
    //     }
    //   }

    //   if (this.payment.loyalty) {
    //     if (this.order.payment.method.toLowerCase().includes("loyalty") == false) {
    //       this.order.payment.method += " loyalty"
    //     }
    //   }
    //   else {
    //     if (this.order.payment.method.toLowerCase().includes("loyalty")) {
    //       this.order.payment.method = this.order.payment.method.replace(' loyalty', '')
    //     }
    //   }

    //   if (this.payment.voucher) {
    //     if (this.order.payment.method.toLowerCase().includes("gift") == false) {
    //       this.order.payment.method += " gift"
    //     }
    //   }
    //   else {
    //     if (this.order.payment.method.toLowerCase().includes("gift")) {
    //       this.order.payment.method = this.order.payment.method.replace(' gift', '')
    //     }
    //   }

    //   if (this.payment.card) {
    //     if (this.order.payment.method.toLowerCase().includes("card") == false) {
    //       this.order.payment.method += " card"
    //     }
    //   }
    //   else {
    //     if (this.order.payment.method.toLowerCase().includes("card")) {
    //       this.order.payment.method = this.order.payment.method.replace(' card', '')
    //     }
    //   }

    //   if (this.order.delivery.contact_method !== '' && this.order.delivery.hour !== '' && this.order.delivery.deliverydate !== '') {
    //     isValidate.push(true)
    //   }

    //   if (this.order.delivery.contact_method == '') {
    //     isValidate.push(false)
    //     field.push('contact method')
    //   }

    //   if (this.order.delivery.hour == '') {
    //     isValidate.push(false)
    //     field.push('delivery hour')
    //   }

    //   if (this.order.delivery.deliverydate == '') {
    //     isValidate.push(false)
    //     field.push('delivery date')
    //   }

    //   if (this.order.payment.method == '') {
    //     isValidate.push(false)
    //     field.push('payment method')
    //   }

    //   if (!isValidate.includes(false)) {

    //     if (this.clearance) {
    //       if(this.isLoggedIn && (Number(this.user.available_balance) >
    //        0 || Number(this.top_up_transaction.amount) > 0)) {
    //         if(this.user.available_balance > 0){
    //           $('#walletpinmodal').modal('show')
    //         }
    //         else{

    //           this.finaliseOrder();

    //         }
    //       }
    //       else {
    //         let req = {
    //             what: "placeorder",
    //             showLoader: true,
    //             data: this.order
    //           }
    //           this.$request
    //           .makePostRequest(req)
    //           .then(res => {
    //             // console.log(res.data.data.order);
    //             // if(this.balance > 0) {
    //               if (this.order.payment.method.includes("gift")&& this.giftcard_amount > 0) {
    //                 // Deduct gift card amount from total cart price
    //                 this.balance = Number(this.order.order_total) - Number(this.giftcard_amount);
    //                 this.payGift(res.data.data.order)
    //               }
    //               else {
    //                 if(this.balance > 0) {
    //                   this.payCard(res.data.data.order)
    //                 }
    //               }
    //             // }
    //           })
    //           .catch(error => {
    //             console.log(error);
    //             this.$swal.fire("Error", error.message, "error");
    //           });
    //       }
    //     }
    //     else {
    //       this.$swal.fire("Notice", 'You have not accepted our Terms & Conditions', "warning");
    //     }

    //   }
    //   else {
    //       let nonfields_err = `${field.toString()}`;
    //       console.log(nonfields_err)

    //       if(nonfields_err == "contact method,delivery hour"){
    //         $('html, body').animate({ scrollTop: $(".mode").offset().top }, 1300);
    //         $(".fulfillment_err").html(`Kindly select your preferred ${field.toString()}`, "error");
    //       }
    //       else if(nonfields_err == "contact method") {
    //         $('html, body').animate({ scrollTop: $(".contact_method_err").offset().top }, 1300);
    //         $(".contact_err").html(`Kindly select your preferred ${field.toString()}`, "error");
    //       }
    //       else if(nonfields_err == "delivery hour"){
    //         $('html, body').animate({ scrollTop: $(".mode").offset().top }, 1300);
    //         $(".fulfillment_err").html(`Kindly select your preferred ${field.toString()}`, "error");
    //       }
    //       else{
    //         this.$swal.fire("Error", `Kindly select your preferred ${field.toString()}`, "error");
    //       }
    //   }

    //   // Track the add_shipping_info event
    //   if (Array.isArray(this.order.order_items)) {
    //       const shippingItems = this.order.order_items.map(item => ({
    //           item_id: item.product.id,
    //           item_name: item.product.name,
    //           item_category: item.order_type,
    //           item_unitPrice: item.unit_price,
    //           quantity: item.quantity,
    //           price: item.price,
    //       }));

    //       // Check if `gtag` is defined before sending the event
    //       if (typeof gtag !== 'undefined') {
    //           gtag('event', 'add_shipping_info', {
    //               currency: 'NGN', // Adjust the currency as needed
    //               value: this.order.order_total, // Total order price
    //               shipping_tier: this.order.delivery.method || 'Standard', // Shipping method/tier
    //               payment_method: this.order.payment.method || '', // Payment method, if available
    //               cart_subtotal: this.order.cart_subtotal,
    //               store: this.order.store,
    //               address: {
    //                   city: this.order.delivery.city,
    //                   state: this.order.delivery.state,
    //                   address: this.order.delivery.address,
    //                   country: 'Nigeria',
    //               }, // Address details
    //               customer: {
    //                   email: this.order.customer.email,
    //                   firstname: this.order.customer.firstname,
    //                   lastname: this.order.customer.lastname,
    //                   phone: this.order.customer.phone,
    //               },
    //               items: shippingItems, // Items in the order
    //           });

    //           console.log('add_shipping_info event sent to Google Analytics');
    //       } else {
    //           console.warn('gtag is not defined. Cannot send add_shipping_info.');
    //       }
    //   } else {
    //       console.warn('order.order_items is not defined or is not an array.');
    //   }
    //   // ========

    // },

    // Old modification
    placeOrder() {
      if (
        this.isLoggedIn &&
        this.default_address.address == undefined &&
        this.order.delivery.method == "delivery"
      ) {
        $("html, body").animate(
          { scrollTop: $(".form_section").offset().top },
          1200
        );
        $(".delivery_address_err").html("Kindly enter your delivery address.");
      } else if (
        (this.order.delivery.address == "" ||
          this.order.delivery.city == "" ||
          this.order.delivery.state == "" ||
          this.order.delivery.landmark == "") &&
        this.order.delivery.method == "delivery"
      ) {
        $("html, body").animate(
          { scrollTop: $(".form_section").offset().top },
          1200
        );
        $(".delivery_address_err").html("Kindly enter your delivery address.");
        return;
      }
      this.order.order_type = "Groceries";
      this.order.unique_code =
        "Gr" +
        this.formatUnique(this.order.store) +
        this.formatUnique(this.store.branch_code) +
        Math.floor(20000 + Math.random() * 90000);
      this.order.contact_upon_delivery_number =
        this.order.contact_upon_delivery_number.replace(/\s/g, "");
      this.order.order_enquiry_contactnumber =
        this.order.order_enquiry_contactnumber.replace(/\s/g, "");
      this.order.customer.phone = this.order.customer.phone.replace(/\s/g, "");
      let isValidate = [];
      let field = [];
      this.order.payment.method = "";
      console.log("help me log the order:",this.order)

      // 🔹 Track selected payment method (Paystack or Flutterwave)
      if (this.selectedPaymentMethod === "paystack") {
        this.order.payment.method = "Paystack";
      } else if (this.selectedPaymentMethod === "flutterwave") {
        this.order.payment.method = "Flutterwave";
      }
      
      // 🔹 Add additional payment types
      if (this.payment.loyalty) {
        this.order.payment.method += this.order.payment.method
          ? " + Loyalty"
          : "Loyalty";
      }
      if (this.payment.voucher) {
        this.order.payment.method += this.order.payment.method
          ? " + Gift"
          : "Gift";
      }
      // if (this.payment.card) {
      //   this.order.payment.method += this.order.payment.method
      //     ? " + Card"
      //     : "Card";
      // }

      if (this.payment.card) {
        if (this.order.payment.method.toLowerCase().includes("card") == false) {
          this.order.payment.method += " card"
          console.log("payment_method:",this.order.payment.method)
           this.order.payment.type=this.selectedPaymentMethod;
        }
      }
      else {
        if (this.order.payment.method.toLowerCase().includes("card")) {
          this.order.payment.method = this.order.payment.method.replace(' card', '')
          console.log("payment_method:",this.order.payment.method)
           this.order.payment.type=this.selectedPaymentMethod;
        }
      }

      if (
        this.order.delivery.contact_method !== "" &&
        this.order.delivery.hour !== "" &&
        this.order.delivery.deliverydate !== ""
      ) {
        isValidate.push(true);
      }
      if (this.order.delivery.contact_method == "") {
        isValidate.push(false);
        field.push("contact method");
      }
      if (this.order.delivery.hour == "") {
        isValidate.push(false);
        field.push("delivery hour");
      }
      if (this.order.delivery.deliverydate == "") {
        isValidate.push(false);
        field.push("delivery date");
      }
      if (this.order.payment.method == "") {
        isValidate.push(false);
        field.push("payment method");
      }
      if (!isValidate.includes(false)) {
        if (this.clearance) {
          let req = {
            what: "placeorder",
            showLoader: true,
            data: this.order,
          };
          this.$request
            .makePostRequest(req)
            .then((res) => {
              console.log("api response:",res)
              if (
                this.order.payment.method.includes("Gift") &&
                this.giftcard_amount > 0
              ) {
                this.balance =
                  Number(this.order.order_total) - Number(this.giftcard_amount);
                this.payGift(res.data.data.order);
              } else {
                if (this.balance > 0) {
                  // 🔹 Call Paystack or Flutterwave based on selection
                  if (this.selectedPaymentMethod === "paystack") {
                    this.payWithPaystack(res.data.data.order);
                  } else if (this.selectedPaymentMethod === "flutterwave") {
                    this.payWithFlutterwave(res.data.data.order);
                  }
                
                }
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire("Error", error.message, "error");
            });
        } else {
          this.$swal.fire(
            "Notice",
            "You have not accepted our Terms & Conditions",
            "warning"
          );
        }
      } else {
        let nonfields_err = `${field.toString()}`;
        console.log(nonfields_err);
        if (nonfields_err == "contact method,delivery hour") {
          $("html, body").animate({ scrollTop: $(".mode").offset().top }, 1300);
          $(".fulfillment_err").html(
            `Kindly select your preferred ${field.toString()}`,
            "error"
          );
        } else if (nonfields_err == "contact method") {
          $("html, body").animate(
            { scrollTop: $(".contact_method_err").offset().top },
            1300
          );
          $(".contact_err").html(
            `Kindly select your preferred ${field.toString()}`,
            "error"
          );
        } else if (nonfields_err == "delivery hour") {
          $("html, body").animate({ scrollTop: $(".mode").offset().top }, 1300);
          $(".fulfillment_err").html(
            `Kindly select your preferred ${field.toString()}`,
            "error"
          );
        } else {
          this.$swal.fire(
            "Error",
            `Kindly select your preferred ${field.toString()}`,
            "error"
          );
        }
      }

      // 🔹 Google Analytics event tracking
      if (Array.isArray(this.order.order_items)) {
        const shippingItems = this.order.order_items.map((item) => ({
          item_id: item.product.id,
          item_name: item.product.name,
          item_category: item.order_type,
          item_unitPrice: item.unit_price,
          quantity: item.quantity,
          price: item.price,
        }));

        if (typeof gtag !== "undefined") {
          gtag("event", "add_shipping_info", {
            currency: "NGN",
            value: this.order.order_total,
            shipping_tier: this.order.delivery.method || "Standard",
            payment_method: this.order.payment.method || "",
            cart_subtotal: this.order.cart_subtotal,
            store: this.order.store,
            address: {
              city: this.order.delivery.city,
              state: this.order.delivery.state,
              address: this.order.delivery.address,
              country: "Nigeria",
            },
            customer: {
              email: this.order.customer.email,
              firstname: this.order.customer.firstname,
              lastname: this.order.customer.lastname,
              phone: this.order.customer.phone,
            },
            items: shippingItems,
          });

          console.log("add_shipping_info event sent to Google Analytics");
        } else {
          console.warn("gtag is not defined. Cannot send add_shipping_info.");
        }
      } else {
        console.warn("order.order_items is not defined or is not an array.");
      }
    },

    finaliseOrder() {
      if (Number(this.top_up_transaction.amount) > 0) {
        this.makeTransaction("creditWallet", this.top_up_transaction);
      }
      if (Number(this.user.available_balance) > 0) {
        this.makeTransaction("debitWallet", this.transaction);
      }

      let req = {
        what: "placeorder",
        showLoader: false,
        data: this.order,
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          // console.log(res.data.data.order);
          if (this.balance > 0 || this.top_up_transaction.amount > 0) {
            if (this.order.payment.method.includes("gift")) {
              this.payGift(res.data.data.order);
            } else {
              // this.payCard(res.data.data.order)
              // Determine if Paystack or Flutterwave should be used
              if (this.selectedPaymentMethod === "paystack") {
                this.payWithPaystack(res.data.data.order);
              } else if (this.selectedPaymentMethod === "flutterwave") {
                this.payWithFlutterwave(res.data.data.order);
              } else {
                console.warn("No payment gateway selected.");
              }
            }
          } else {
            let order = res.data.data.order;
            let req = {
              what: "verifypayment",
              showLoader: true,
              data: {
                reference: null,
                payment_ref: null,
                order_id: order.id,
                user_id: order.user_id,
                cart_id: "",
                customer_id: "",
                status: "successful",
                amount: Number(this.balance),
                gateway: this.selectedPaymentMethod,
              },
            };

            this.verifyPayment(this, req, order);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire("Error", error.message, "error");
        });
    },

    // payWithFlutterwave (order, giftref) {
    //   // live
    //   // let PBFKey = "FLWPUBK-f079ea84da7aac9ca312a10668f88c44-X";

    //   // test
    //   // let PBFKey = "FLWPUBK-00fd26c8dc92b4e1663550c4ba7532aa-X";
    //   let PBFKey = this.$request.PBFFLWKey;
    //   let transid = giftref ? giftref : `${order.id}${Math.floor(Date.now())}`;
    //   let vm = this;
    //   let cardamount;
    //   if (Number(this.balance) !== "" && this.balance > 0) {
    //     cardamount = this.balance

    //   }
    //   else if(this.isLoggedIn) {
    //     cardamount = Number(this.balance)
    //   }
    //   else {
    //     cardamount = order.balance
    //   }
    //   getpaidSetup({

    //     PBFPubKey: PBFKey,
    //     customer_email: this.order.customer.email,
    //     customer_firstname: this.order.customer.firstname,
    //     customer_lastname: this.order.customer.lastname,
    //     custom_description: "Payment for order made",
    //     custom_logo: "https:marketsquareng.com/assets/img/logo_mobile.png",
    //     custom_title: "Market Square",
    //     amount: cardamount,
    //     customer_phone: this.order.customer.phone,
    //     country: "NG",
    //     currency: "NGN",
    //     txref: order.unique_code,
    //     onclose: function () { },
    //     callback: function (response) {
    //       var flw_ref = response.tx.flwRef; // collect flwRef returned and pass to a 					server page to complete status check.
    //       let txref = response.tx.txRef;
    //       let status = response.tx.status;
    //       let amount = response.tx.amount;
    //       let chargeResponse = response.tx.chargeResponseCode;

    //       if (chargeResponse == "00" || chargeResponse == "0") {
    //         let req;
    //         if (!vm.$store.getters.isLoggedIn) {
    //           req = {
    //             what: "verifypayment",
    //             showLoader: true,
    //             data: {
    //               txref: txref,
    //               pref: flw_ref,
    //               order_id: order.id,
    //               user_id: "",
    //               cart_id: "",
    //               customer_id: order.customer_id,
    //               status: status,
    //               amount: amount
    //             }
    //           }
    //         }
    //         else {
    //           req = {
    //             what: "verifypayment",
    //             showLoader: true,
    //             data: {
    //               txref: txref,
    //               pref: flw_ref,
    //               order_id: order.id,
    //               user_id: order.user_id,
    //               cart_id: "",
    //               customer_id: "",
    //               status: status,
    //               amount: amount
    //             }
    //           }
    //         }

    //        vm.verifyFlutterPayment(vm, req, order);

    //       } else {
    //         //Add your failure page here
    //         vm.$swal.fire({
    //           icon: 'error',
    //           type: "error",
    //           title: 'Error',
    //           text: 'Payment Failed!!!',
    //         })
    //       }
    //     }
    //   });
    // },

    async payWithFlutterwave(order, giftref) {
    let scriptExists = document.querySelector('script[src="https://checkout.flutterwave.com/v3.js"]');

    if (!scriptExists) {
        const script = document.createElement("script");
        script.src = "https://checkout.flutterwave.com/v3.js";
        script.async = true;
        script.onload = () => this.initiateFlutterwave(order, giftref); // Run after script loads
        document.body.appendChild(script);
    } else {
        this.initiateFlutterwave(order, giftref);
    }
},

initiateFlutterwave(order, giftref) {
    let PBFKey = this.$request.PBFFLWKey;
    let transid = giftref ? giftref : `${order.id}${Math.floor(Date.now())}`;
    let vm = this;
    let cardamount = Number(this.balance) > 0 ? this.balance : order.balance;

    if (typeof FlutterwaveCheckout !== "function") {
        console.error("❌ Flutterwave SDK not loaded.");
        vm.$swal.fire("Error", "Payment service is not available. Please refresh and try again.", "error");
        return;
    }
    FlutterwaveCheckout({
        public_key: PBFKey,
        tx_ref: order.unique_code,
        amount: cardamount,
        currency: "NGN",
        payment_options: "card, banktransfer, ussd",
        customer: {
            email: order.customer.email,
            phone_number: order.customer.phone,
            name: `${order.customer.firstname} ${order.customer.lastname}`,
        },
        customizations: {
            title: "Market Square",
            description: "Payment for order",
            logo: "https://marketsquareng.com/assets/img/logo_mobile.png",
        },
        callback: async (response) => {
          // console.log("flutterwave response:",response)
            
            let txref = response.tx_ref;
            let transaction_id = response.transaction_id;
            let status = response.status;
            let amount = response.amount;
            const orderId = order.id;

           
            if(transaction_id){
               const apiUrl = "https://admin.sundrymarkets.com/api/flutterPaymentId";
                try {
                  const response = await axios.post(apiUrl, {
                      paymentId: String(transaction_id),
                      orderId: orderId
                  }, {
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                      },
                  });

                  if (response.data.status) {
                      console.log("✅ Payment ID saved to order table");
                  }
              } catch (err) {
                  console.error("❌ Payment ID save error", err);
              }
            }


            if (status === "completed") {
                let req = {
                    what: "verifypayment",
                    showLoader: true,
                    data: {
                        txref,
                        transaction_id,
                        order_id: order.id,
                        user_id: vm.$store.getters.isLoggedIn ? order.user_id : "",
                        customer_id: order.customer_id,
                        status,
                        amount,
                    },
                };

                vm.verifyFlutterPayment(vm, txref, transaction_id, order,amount);

            } else {
                vm.$swal.fire("Error", "Payment Failed!!!", "error");
            }
        },
        onclose: function () {
            console.warn("Payment popup closed.");
        },
    });
},



  async verifyFlutterPayment(vm, txref, transaction_id, order, amount) {

    console.log("reference:",txref)
    const apiUrl = "https://admin.sundrymarkets.com/api/verifyFlutterwavePayment";

    try {
        // Show loader
        vm.loading = true;


        // Make API request
        const response = await axios.post(apiUrl, {
            txref:txref,
            transaction_id: String(transaction_id),
            order_id: order.id,
            amount,
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });


        if (response.data.success) {

            // Prepare payment items for Google Analytics
            const paymentItems = order.orderitems.map((item) => ({
                item_id: item.product.id,
                item_name: item.product.name,
                item_category: item.category,
                item_unitPrice: item.sellingprice,
                quantity: item.quantity,
            }));

            // Google Analytics Tracking
            try {
                if (typeof gtag !== "undefined") {
                    gtag("event", "purchase", {
                        transaction_id: order.unique_code,
                        affiliation: "Market Square",
                        value: order.order_total,
                        currency: "NGN",
                        items: paymentItems,
                        amount_paid: order.amount_paid,
                        customer_email: order.customer.email,
                        customer_firstname: order.customer.firstname,
                        customer_lastname: order.customer.lastname,
                        customer_phone: order.customer.phone,
                        payment_method: order.payment_method,
                        user_id: order.user_id,
                    });
                    console.log("📊 Purchase event sent to Google Analytics");
                } else {
                    console.warn("⚠️ gtag is not defined. Cannot send purchase event.");
                }
            } catch (gaError) {
                console.error("❌ Google Analytics Error:", gaError);
            }

            // Vuex Store Updates
            try {
                await vm.$store.dispatch("orderinfo", order);
                await vm.$store.dispatch("addToCart", []);
                await vm.$store.dispatch("user", order.user);
            } catch (storeError) {
                console.error("❌ Vuex Store Update Error:", storeError);
            }

            // Success Alert & Redirect

            vm.$swal.fire({
                title: "🎉 Success!",
                html: "Order Payment Successful!!!",
                icon: "success",
                timer: 5000,
                didClose: async () => {
                    await vm.$router.push("/confirm");
                    setTimeout(() => { window.location.reload(); }, 1000); // Delay reload
                },
            });

        } else {
            vm.$swal.fire("Error", response.data.message || "Transaction reference not found.", "error");
        }
    } catch (error) {
        let errorMessage = "Failed to verify transaction reference. Please try again.";

        if (error.response) {

            errorMessage = error.response.data.message || errorMessage;
        }

        vm.$swal.fire("Error!", errorMessage, "error");
    } finally {
        // Hide loader
        vm.loading = false;
    }
},
  
  

    // payCard(order, giftref) {
    //   console.log("Starting Paystack transaction...");
    //   let vm = this;
    //   let transid = giftref ? giftref : `${order.id}${Math.floor(Date.now())}`;
    //   let popup = new PaystackPop();

    //   // Get the exact amount
    //   let cardamount = Number(this.balance) > 0 ? this.balance : order.balance;

    //   // If the transaction reference already exists, resume it
    //   if (giftref) {
    //     console.log(`Resuming Paystack transaction with reference: ${giftref}`);
    //     popup.resumeTransaction(giftref);  // Resume existing transaction
    //     return;
    //   }

    //   // Create a new transaction
    //   let req = {
    //     what: "makePayment",
    //     showLoader: true,
    //     data: {
    //       email: order.customer.email,
    //       amount: cardamount,
    //     }
    //   };

    //   this.$request
    //     .makePostRequest(req)
    //     .then(res => {
    //       console.log("Response from backend:", res);

    //       if (res.status && res.data.response_body && res.data.response_body.data) {
    //         const paymentData = res.data.response_body.data;
    //         const reference = paymentData.reference;
    //         const accessCode = paymentData.access_code; // Get the access code

    //         if (!reference) {
    //           console.error("Transaction failed: Missing reference.");
    //           this.$swal.fire("Error", "Transaction failed: Missing reference.", "error");
    //           return;
    //         }

    //         if (accessCode) {
    //           console.log(`Resuming Paystack transaction with access code: ${accessCode}`);
    //           this.resumeTransaction(order, accessCode);  // Resume with access code
    //         } else {
    //           console.log(`Initializing new Paystack payment with reference: ${reference}`);
    //           this.initializePaystackPayment(order, reference, cardamount);  // Initialize new payment
    //         }
    //       } else {
    //         console.error("Transaction initialization failed:", res.message || "Unknown error");
    //         this.$swal.fire("Error", "Transaction initialization failed: " + (res.message || "Unknown error"), "error");
    //       }
    //     })
    //     .catch(error => {
    //       console.error("Error initializing transaction:", error);
    //       this.$swal.fire("Error", "An error occurred while initializing payment: " + error.message, "error");
    //     });
    // },

    // verifyPaystackPayment(reference, orderId, userId, amount) {
    //   console.log("Verifying payment with reference:", reference);
    //   let vm = this;
    //   let req = {
    //     what: "verifyPayment",
    //     showLoader: true,
    //     data: {
    //       reference: reference,
    //       orderId: orderId,
    //       userId: userId,
    //       amount: amount
    //     }
    //   };

    //   this.$request
    //     .makePostRequest(req)  // Changed from GET to POST assuming backend requires a POST request
    //     .then(res => {
    //       console.log("Payment verification response:", res);

    //       if (res.success) {
    //         console.log("Payment verified successfully:", res.data);

    //         // Track the purchase event
    //         const purchaseItems = res.data.order_items.map(item => ({
    //           item_id: item.product.id,
    //           item_name: item.product.name,
    //           item_category: item.order_type,
    //           item_unitPrice: item.unit_price,
    //           quantity: item.quantity,
    //           price: item.price,
    //         }));

    //         if (typeof gtag !== 'undefined') {
    //           gtag('event', 'purchase', {
    //             transaction_id: res.data.unique_code,
    //             affiliation: 'Market Square',
    //             value: res.data.order_total,
    //             currency: 'NGN',
    //             items: purchaseItems,
    //             amount_paid: res.data.amount_paid,
    //             customer_email: res.data.customer.email,
    //             customer_firstname: res.data.customer.firstname,
    //             customer_lastname: res.data.customer.lastname,
    //             customer_phone: res.data.customer.phone,
    //             payment_method: res.data.payment.method,
    //             user_id: res.data.user_id
    //           });

    //           console.log('purchase event sent to Google Analytics');
    //         } else {
    //           console.warn('gtag is not defined. Cannot send purchase event.');
    //         }

    //         // Dispatch order info and update store state
    //         vm.$store.dispatch('orderinfo', res.data);
    //         vm.$store.dispatch('addToCart', []);
    //         vm.$store.dispatch('user', res.data.user);

    //         vm.$swal.fire({
    //           title: 'Success!',
    //           html: 'Order Payment Successful!!!',
    //           timer: 5000,
    //           onBeforeOpen: () => {
    //             vm.$swal.showLoading();
    //           },
    //           onClose: () => {
    //             $('#walletpinmodal').modal('close');
    //             vm.$router.push('/confirm');
    //           },
    //         });
    //       } else {
    //         console.error("Payment verification failed:", res.message);
    //         vm.$swal.fire('Error', 'Payment verification failed: ' + res.message, 'error');
    //       }
    //     })
    //     .catch(error => {
    //       console.error("Error verifying payment:", error);
    //       vm.$swal.fire('Error', 'An error occurred while verifying payment: ' + error.message, 'error');
    //     });
    // }
    // ,
    payWithPaystack(order, giftref) {
      // live
      // let PBFKey = "FLWPUBK-f079ea84da7aac9ca312a10668f88c44-X";

      // test
      // let PBFKey = "FLWPUBK-00fd26c8dc92b4e1663550c4ba7532aa-X";
      let vm = this;
      let paystackPublicKey = this.$request.PBFKey; // Ensure this is set in .env
      let transactionRef = giftref ? giftref : order.unique_code;
      let cardAmount;
      if (Number(this.balance) !== "" && this.balance > 0) {
        cardAmount = this.balance;
      } else if (this.isLoggedIn) {
        cardAmount = Number(this.balance);
      } else {
        cardAmount = order.balance;
      }
      console.log("transactionRef:", transactionRef);
      let handler = new PaystackPop();

      handler.newTransaction({
        key: paystackPublicKey, // Paystack Public Key
        email: this.order.customer.email,
        amount: cardAmount * 100, // Convert to Kobo
        currency: "NGN",
        ref: transactionRef,
        label: "Market Square",
        first_name: this.order.customer.firstname,
        last_name: this.order.customer.lastname,
        order_unique_code: this.order.unique_code,
        metadata: {
          custom_fields: [
            {
              display_name: "Customer Name",
              variable_name: "customer_name",
              value: `${this.order.customer.firstname} ${this.order.customer.lastname}`,
            },
            {
              display_name: "Phone Number",
              variable_name: "customer_phone",
              value: this.order.customer.phone,
            },
          ],
        },
        onSuccess: function (response) {
          console.log("success+++", response);
          let reference = response.reference; // Correct Paystack reference field
          let status = response.status;
          console.log(reference);
          if (status === "success") {
            let req = {
              what: "verifypayment",
              showLoader: true,
              data: {
                reference: reference,
                order_id: order.id,
                user_id: vm.isLoggedIn ? order.user_id : "",
                customer_id: vm.isLoggedIn ? "" : order.customer_id,
                status: response.status,
                amount: cardAmount,
              },
            };
            console.log(req.data);
            vm.verifyPaystackPayment(vm, req, order); // Call verification function
          } else {
            vm.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Payment Failed!!!",
            });
          }
        },
        onCancel: function () {
          console.log("Payment window closed");
        },
      });
    },

    async verifyPaystackPayment(vm, req, order) {
      let { reference, order_id, amount, status } = req.data;
      let transaction_ref = req.data.id;

      if (!reference) {
        vm.$swal.fire(
          "Warning",
          "Please provide a transaction reference.",
          "warning"
        );
        return;
      }

  

      let apiUrl = "https://admin.sundrymarkets.com/api/verifyPaystackPayment";

      try {
        // Show loader (if you have a loader state)
        vm.loading = true;

        // Make API request
        const response = await axios.post(
          apiUrl,
          {
            reference,
            order_id,
            amount,
            status,
            transaction_ref,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );


        if (response.data.success) {
          console.log("✅ Payment Verified Successfully");

          // Prepare payment items for Google Analytics
          const paymentItems = order.orderitems.map((item) => ({
            item_id: item.product.id,
            item_name: item.product.name,
            item_category: item.category,
            item_unitPrice: item.sellingprice,
            quantity: item.quantity,
          }));

          // Google Analytics Tracking
          try {
            if (typeof gtag !== "undefined") {
              gtag("event", "purchase", {
                transaction_id: order.unique_code,
                affiliation: "Market Square",
                value: order.order_total,
                currency: "NGN",
                items: paymentItems,
                amount_paid: order.amount_paid,
                customer_email: order.customer.email,
                customer_firstname: order.customer.firstname,
                customer_lastname: order.customer.lastname,
                customer_phone: order.customer.phone,
                payment_method: order.payment_method,
                user_id: order.user_id,
              });
              console.log("📊 Purchase event sent to Google Analytics");
            } else {
              console.warn(
                "⚠️ gtag is not defined. Cannot send purchase event."
              );
            }
          } catch (gaError) {
            console.error("❌ Google Analytics Error:", gaError);
          }

          // Vuex Store Updates
          try {
            await vm.$store.dispatch("orderinfo", order);
            await vm.$store.dispatch("addToCart", []);
            await vm.$store.dispatch("user", order.user);
          } catch (storeError) {
            console.error("❌ Vuex Store Update Error:", storeError);
          }

          // Success Alert & Redirect
          vm.$swal.fire({
            title: "🎉 Success!",
            html: "Order Payment Successful!!!",
            icon: "success",
            timer: 5000,
            willClose: () => {
              vm.$router.push("/confirm");
            },
          });
        } else {
          console.warn(
            "⚠️ Payment verification failed:",
            response.data.message
          );
          vm.$swal.fire(
            "Error",
            response.data.message || "Transaction reference not found.",
            "error"
          );
        }
      } catch (error) {
        console.error("❌ Error verifying transaction:", error);

        let errorMessage =
          "Failed to verify transaction reference. Please try again.";

        if (error.response) {
          console.error("❌ Server Error Response:", error.response.data);
          errorMessage = error.response.data.message || errorMessage;
        }

        vm.$swal.fire("Error!", errorMessage, "error");
      } finally {
        // Hide loader
        vm.loading = false;
      }
    },

    editAd(row) {
      this.edit = true;
      this.addressid = row.id;
      this.address = {
        label: row.label,
        area: row.area,
        state: row.state,
        address: row.address,
        city: row.city,
        landmark: row.landmark,
        address_default: Number(row.address_default),
      };
    },

    handleDelete(id) {
      let req = {
        what: "deleteaddress",
        showLoader: true,
        id: id,
      };
      this.$request
        .deleteItem(req)
        .then((res) => {
          this.$swal.fire("Success", res.data.message, "success");
          this.fetchAddress();
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire("Error", error.message, "error");
        });
    },
  },
};
</script>

<style scoped>
label.famount {
  position: relative;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 4px 9px;
  cursor: pointer;
  align-items: center;
  margin-right: 4px;
}
input[type="radio"] {
  margin-right: 8px;
  margin-top: 2px;
}

input::placeholder {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
}

.paywalletinput {
  margin-top: 0px;
  height: 28px;
  width: 181px;
  color: #212529;
  border: 1px solid #cecece;
  margin-right: 4px;
  border-radius: 5px;
}
</style>
